import { Component, OnInit, Inject, ViewChild, ElementRef, ViewContainerRef, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FeedbackService,
        MoonDeskDocument,
        ClassValue,
        ContentTextRuleConfiguration,
        AuthService,
        RuleIDs,
        RuleConfiguration,
        Identity,
        RuleClassValue,
        Class,
        RuleDocumentType,
        DocType,
        DocumentService,
        TextsFormattingService,
        RulesService
      } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { IllustratorService } from '../../../services/illustrator.service';
import { debounceTime } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';
import { BACKEND_INFO } from 'src/app/services/illustrator-config.service';
import { RuleHelperService } from 'src/app/services/rule-helper.service';


@Component({
  selector: 'app-rule-create',
  templateUrl: './rule-create.component.html',
  styleUrls: ['./rule-create.component.scss']
})
export class RuleCreateComponent implements OnInit {

  description: UntypedFormControl = new UntypedFormControl('', Validators.required);
  documentContent: UntypedFormControl = new UntypedFormControl('' , Validators.required);
  caseSensitive: boolean = false;
  textSize: UntypedFormControl = new UntypedFormControl('0' , Validators.required);

  richCheckText: string;
  shouldExists: boolean = true;

  ruleBusy: boolean;
  identity: Identity;

  allowDuplicateDocs: boolean;

  document: MoonDeskDocument;
  rule: RuleConfiguration;
  reducedClasses: Class[] = [];
  allClasses: Class[] = [];

  // DocumentTypes
  documentTypes: DocType[] = [];
  filteredDocTypes: DocType[] = [];
  docTypeSearchText: UntypedFormControl = new UntypedFormControl('');
  selectedDocumentTypes: DocType[] = [];

  // ClassValues
  plainClassValues: ClassValue[] = [];
  filteredClassValues: ClassValue[] = [];
  clsValueSearchText: UntypedFormControl = new UntypedFormControl('');
  selectedClassValues: ClassValue[] = [];

  @ViewChild('searchClassValues') searchClassValues: TemplateRef<any>;
  @ViewChild('searchDocTypes') searchDocTypes: TemplateRef<any>;
  overlayRef: OverlayRef;


  constructor(
    public dialogRef: MatDialogRef<RuleCreateComponent>,
    private feedbackService: FeedbackService,
    private rulesService: RulesService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private illustratorService: IllustratorService,
    private docService: DocumentService,
    private overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    private textsFormattingService: TextsFormattingService,
    private rulesHelperService: RuleHelperService)
  {
    this.document = data.document;
    this.rule = data.rule;

    this.identity = this.authService.getCurrentIdentity();

    this.allClasses = this.docService.getPlainClassList(this.identity.company.classes);
    this.clsValueSearchText.valueChanges.pipe(debounceTime(500)).subscribe((searchText: string) =>
    {
      this.filterClassValues(searchText);
    });

    this.documentTypes = _.filter(this.identity.company.documentTypes, dt => !dt.isLibraryType);
    this.documentTypes = this.sortDocTypes(this.documentTypes);

    if (this.documentTypes.length !== this.allClasses.length)
    {
      for (const docType of this.documentTypes)
      {
          if (docType && docType.classes)
          {
            for (const cls of docType.classes)
            {
              if (!_.find(this.reducedClasses , rc => rc.id === cls.id))
              {
                const fullClass = _.find(this.allClasses , fclass => fclass.id === cls.id);
                this.reducedClasses.push(fullClass);
                fullClass.children?.forEach(childClass =>
                {
                  this.reducedClasses.push(childClass);
                });
              }
            }
          }
      }
    }
    if (!this.reducedClasses || (this.reducedClasses && this.reducedClasses.length === 0))
    {
      this.reducedClasses = this.allClasses;
    }

    this.plainClassValues = this.docService.getPlainClassValuesList(this.reducedClasses);
    this.plainClassValues = this.sortClassValues(this.plainClassValues);
    this.docTypeSearchText.valueChanges.pipe(debounceTime(500)).subscribe((searchText: string) =>
    {
      this.filterDocTypes(searchText);
    });
  }

  async ngOnInit()
  {
    this.preSelect();
  }

  async getCurrentSelection()
  {
    try
    {
      const selection = await this.illustratorService.getCurrentTextSelection();
      if (selection)
      {
        this.documentContent.setValue(selection);
        const oneLineSelection = this.textsFormattingService.formatString(selection, true, false, false);
        const ruleName = `Should exist ${oneLineSelection}`;
        this.description.setValue(ruleName);
      }

    }
    catch (err)
    {
      this.feedbackService.notifyError('Error copying current selection of document' , err);
    }
  }

  preSelect()
  {
    if (this.rule)
    {
      this.selectedDocumentTypes = _.map(this.rule.ruleDocumentTypes, ruleDt => ruleDt.documentType);
      this.selectedClassValues = _.map(this.rule.ruleClassValues, ruleCv => ruleCv.classValue);
      this.selectedClassValues.forEach(cv =>
      {
        cv.class = _.find(this.allClasses, cls => cls.id === cv.classId);
      });
      this.selectedClassValues = this.sortClassValues(this.selectedClassValues);
      this.selectedDocumentTypes = this.sortDocTypes(this.selectedDocumentTypes);

      const ruleOptions: ContentTextRuleConfiguration = JSON.parse(this.rule.ruleOptions);
      this.description.setValue(ruleOptions.ruleDescription);
      this.richCheckText = ruleOptions.richCheckText;
      this.shouldExists = ruleOptions.findCondition === 'exist';
      this.documentContent.setValue(ruleOptions.checkText);
      this.textSize.setValue(ruleOptions.textSize);
      this.caseSensitive = ruleOptions.checkTextCaseSensitive;
    }
    else if (this.document)
    {
      this.selectedDocumentTypes = this.document.documentType ? [this.document.documentType] : [];
      this.selectedClassValues = this.document.classValues;
      this.selectedClassValues.forEach(cv =>
      {
        cv.class = _.find(this.allClasses, cls => cls.id === cv.classId);
      });
      this.selectedClassValues = this.sortClassValues(this.selectedClassValues);
      this.selectedDocumentTypes = this.sortDocTypes(this.selectedDocumentTypes);
      this.getCurrentSelection();
    }
  }

  /**
   * Sort by ClassId then by className then by classValue name
   */
  sortClassValues(classValues: ClassValue[]): ClassValue[]
  {
    return _.chain(classValues)
              .sortBy(cv => cv.classId)
              .sortBy(cv => cv.class.name.toLowerCase())
              .sortBy(cv => cv.name.toLowerCase())
              .value();
  }

  toggleClassValueSearch(elementRef: ElementRef)
  {
    this.filterClassValues('');
    this.clsValueSearchText.setValue('', {emitEvent: false});
    const portal = new TemplatePortal(this.searchClassValues, this._viewContainerRef);
    this.toggleOverlay(elementRef, portal);
  }

  toggleOverlay(elementRef: ElementRef, portal: TemplatePortal)
  {
    if (this.overlayRef)
    {
      this.closeOverlay();
    }
    else
    {
      const connectedStrategy = this.overlay.position()
        .flexibleConnectedTo(elementRef)
        .withPositions([{originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'center'}]);
                              // { originX: 'center', originY: 'bottom' },
                              // { overlayX: 'center', overlayY: 'center' }));
      this.overlayRef = this.overlay.create({
        positionStrategy: connectedStrategy,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop'
      });
      this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
      this.overlayRef.attach(portal);
    }
  }

  closeOverlay()
  {
    this.overlayRef.detach();
    this.overlayRef = undefined;
  }

  filterClassValues(searchText: string)
  {
    const nonSelectedClassValues = _.filter(this.plainClassValues, cv =>
      !_.any(this.selectedClassValues, selectCv => selectCv.id === cv.id)
    );
    const lowerSearchText = searchText.toLowerCase();
    this.filteredClassValues = nonSelectedClassValues;
    this.filteredClassValues = _.filter(nonSelectedClassValues, cv =>
      cv.name.toLowerCase().includes(lowerSearchText) ||
      cv.class.name.toLowerCase().includes(lowerSearchText)
    );
    this.filteredClassValues = this.sortClassValues(this.filteredClassValues);
  }

  addClassValue(classValue: ClassValue)
  {
    this.selectedClassValues.push(classValue);
    this.selectedClassValues = this.sortClassValues(this.selectedClassValues);
    this.closeOverlay();
  }

  removeClassValue(classValue: ClassValue)
  {
    const index = this.selectedClassValues.indexOf(classValue);
    this.selectedClassValues.splice(index, 1);
  }

  /**
   * Sort by docTypeName
   */
  sortDocTypes(docTypes: DocType[]): DocType[]
  {
    return _.sortBy(docTypes, docType => docType.name);
  }

  toggleDocTypesSearch(elementRef: ElementRef)
  {
    this.filterDocTypes('');
    this.docTypeSearchText.setValue('', {emitEvent: false});
    const portal = new TemplatePortal(this.searchDocTypes, this._viewContainerRef);
    this.toggleOverlay(elementRef, portal);
  }

  filterDocTypes(searchText: string)
  {
    const nonSelectedDocTypes = _.filter(this.documentTypes, dt =>
      !_.any(this.selectedDocumentTypes, selectDt => selectDt.id === dt.id)
    );
    const lowerSearchText = searchText.toLowerCase();
    this.filteredDocTypes = nonSelectedDocTypes;
    this.filteredDocTypes = _.filter(nonSelectedDocTypes, dt =>
      dt.name.toLowerCase().includes(lowerSearchText)
    );
    this.filteredDocTypes = this.sortDocTypes(this.filteredDocTypes);
  }

  addDocType(docType: DocType)
  {
    this.selectedDocumentTypes.push(docType);
    this.selectedDocumentTypes = this.sortDocTypes(this.selectedDocumentTypes);
    this.closeOverlay();
  }

  removeDocType(docType: DocType)
  {
    const index = this.selectedDocumentTypes.indexOf(docType);
    this.selectedDocumentTypes.splice(index, 1);
  }

  close()
  {
    this.dialogRef.close();
  }

  async deleteRule()
  {
    if (this.ruleBusy || !this.rule)
    {
      return;
    }
    try
    {
      this.ruleBusy = true;
      const question = 'Are you sure you want to delete this rule?';
      const answer = await this.ask(question);
      if (answer)
      {
        await this.rulesService.deleteRuleConfig(this.rule);
        this.feedbackService.notifyMessage('The rule has been deleted');
        this.close();
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('An error has occurred while trying to delete the rule', err);
    }
    finally
    {
      this.ruleBusy = false;
    }
  }

  async save(close: boolean)
  {
    if (!this.description.valid || !this.documentContent.valid || this.selectedDocumentTypes.length === 0)
    {
      return this.feedbackService.notifyMessage('Please complete the required fields');
    }

    let rule: RuleConfiguration;
    const newRuleConfiguration: ContentTextRuleConfiguration = {
      findCondition: 'exist',
      findText: '',
      checkCondition: 'exist',
      checkText: this.documentContent.value,
      richCheckText: this.richCheckText ? this.richCheckText : this.documentContent.value,
      checkTextCaseSensitive: this.caseSensitive,
      textSize: this.textSize.value && this.textSize.value > 0 ? this.textSize.value : undefined,
      ruleDescription: this.description.value
    };
    const classValuesRule: RuleClassValue [] = [];
    this.selectedClassValues.forEach(cv => classValuesRule.push({classValue: cv}));

    const ruleDocTypes: RuleDocumentType [] = [];
    this.selectedDocumentTypes.forEach(dt => ruleDocTypes.push({documentType: dt}));

    rule = {
      id: this.rule ? this.rule.id : undefined,
      companyId: this.identity.company.id,
      ruleID: RuleIDs.TEXT_CONTENT,
      ruleOptions: JSON.stringify(newRuleConfiguration),
      ruleClassValues: classValuesRule,
      ruleDocumentTypes: ruleDocTypes
    };
    await this.updateRule(rule);
    if (close)
    {
      this.dialogRef.close();
    }
  }

  getCheckTextString(checkText: string): string
  {
    return this.rulesHelperService.getReadableCheckTextString(checkText)
  }

  private async updateRule(rule: RuleConfiguration)
  {
    try
    {
      await this.rulesService.postRuleConfigs([rule]);
      this.feedbackService.notifyMessage('Rule configuration saved');
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error saving rule', err);
    }
  }

  private ask (question: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          title: 'Please confirm',
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'yes'));
    });
  }

  async goToWebRule()
  {
    try
    {
      await this.save(false);
      const id = this.authService.getCurrentIdentity();
      if (!id || !id.company)
      {
        throw new Error('No company');
      }

      const url = `${BACKEND_INFO.ipAddress}/rules/edit/${this.rule.id}`;

      this.illustratorService.openUrl(url);
      this.close();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error opening web url', err);
    }
  }
}
